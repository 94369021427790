function reveal() {
    let reveals = document.querySelectorAll(".reveal");

    for (let i = 0; i < reveals.length; i++) {
        let windowHeight = window.innerHeight;
        let elementTop = reveals[i].getBoundingClientRect().top;

        if (elementTop < windowHeight && !reveals[i].classList.contains('active')) {
            reveals[i].classList.add("active");
            lazyLoadImages(reveals[i]);
        }
    }
}

function shrink() {
    let navbar = document.querySelector('header .navbar');
    if (window.scrollY > 0) {
        navbar.classList.add("shrink");
    } else {
        navbar.classList.remove("shrink");
    }
}

function lazyLoadImages(element) {
    if (typeof element === 'undefined' || element.length === 0) {
        element = document;
    }
    let lazyLoadPicture = element.querySelectorAll('picture.lazy');
    let lazyLoadImages = element.querySelectorAll('img.lazy');
    lazyLoadPicture.forEach(function (picture) {
        let sources = picture.querySelectorAll('source');
        sources.forEach(function (source) {
            source.srcset = source.dataset.srcset;
        });
        let img = picture.querySelectorAll('img');
        img[0].src = img[0].dataset.src;
        picture.classList.remove('lazy');
    });
    lazyLoadImages.forEach(function (img) {
        img.src = img.dataset.src;
        img.classList.remove('lazy');
    });
}

window.addEventListener('scroll', function () {
    reveal();
    shrink();
});

document.addEventListener('click', function (event) {

    // If the clicked element doesn't have the right selector, bail
    if (!event.target.matches('.navbar-toggler') && !event.target.matches('.navbar-toggler-icon')) {
        return;
    }

    // Don't follow the link
    event.preventDefault();
    let navbar = document.querySelector('header .navbar');
    let body = document.querySelector('body');
    if (navbar.classList.contains('toggleClick')) {
        navbar.classList.remove('toggleClick');
        body.classList.remove('no-scroller');
    } else {
        body.classList.add('no-scroller');
        navbar.classList.add('toggleClick');
        shrink();
    }

}, false);
window.dispatchEvent(new Event('scroll'));

if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
    reveal();
    shrink();
    lazyLoadImages([]);
}